import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

import wave_light_blue from "../../../static/images/general/wave-light-blue.svg";
import wave_white from "../../../static/images/slider/hero-wave.png";
import "../../styles/components/elements/page_banner.scss";

const PageBanner = ({ title, bgImage, bgImageMobile, icon, page }) => {
  let wave = null;
  let url = null;

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const pageClass = page.type;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // change wave color based on page type
  if (
    pageClass === "loan_page" ||
    pageClass === "about" ||
    pageClass === "simple_page"
  ) {
    wave = <img src={wave_white} alt="wave" />;
  } else {
    wave = <img src={wave_light_blue} alt="wave" />;
  }

  if (windowWidth <= 768) {
    url = bgImage.gatsbyImageData.images.fallback.src;
  } else {
    url = bgImage.gatsbyImageData.images.fallback.src;
  }

  return (
    <Container
      fluid
      className={`${pageClass} page-banner text-center p-0`}
      style={
        bgImage.gatsbyImageData
          ? {
              backgroundImage: `url(${url})`,
              backgroundSize: "cover",
              backgroundPosition: "right",
            }
          : {}
      }
    >
      <h1 className="hero-title justify-content-center position-relative d-flex flex-column align-items-center flex-md-row max-container col-11 col-sm-9 col-lg-8 title-65">
        {!icon ? null : (
          <GatsbyImage
            image={icon.gatsbyImageData}
            alt={icon.alt || "banner icon"}
            className="banner-icon"
            style={{ width: "138px", height: "138px" }}
          />
        )}
        {title}
      </h1>
      <div className="wave d-none d-md-block">{wave}</div>
    </Container>
  );
};

export default PageBanner;
