import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Row, Col, Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Card from "react-bootstrap/Card";
import * as Slider from "@radix-ui/react-slider";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import PageBanner from "../components/elements/PageBanner";
import components from "../components/slices/index";
import { constructActiveDoc } from "../utilities/helpers";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/pages/loan_calculation.scss";

const LoanCalculation = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const activeLanguage = data.settings.lang;
  const mydata = page.data;
  const form = mydata.form_values[0];
  const icons = mydata.app_icons;
  let customText;

  // Ονομαστικό Επιτόκιο
  const [apr, setApr] = useState(3);
  // Αποπληρωμή σε έτη
  const [term, setTerm] = useState(30);
  // Ποσό δανείου
  const [amt, setAmt] = useState(100000);
  // Μηνιαία Δόση
  const [mPmt, setMPmt] = useState(428.1);

  // Συνολικό Ποσό Αποπληρωμής
  const [totalRepayment, setTotalRepayment] = useState(154117);

  // Τελικό Επιτόκιο
  const [finalRate, setFinalRate] = useState(3.12);

  if (activeLanguage === "en-us") {
    customText = "Minimum loan amount 10.000€";
  } else {
    customText = "Ελάχιστο ποσό δανείου 10.000€";
  }

  const handleAprChange = (e) => {
    setApr(e[0]);
  };

  const handleTermChange = (e) => {
    setTerm(e[0]);
  };

  const formatNumberWithComma = (value, precision = 2) => {
    return parseFloat(value).toLocaleString("el-GR", {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  };

  const handleAmtChange = (e) => {
    if (e.target.value === "") {
      setAmt(100000);
      setMPmt("428.1"); // Set it to the default value when input is empty
    } else {
      const limit = 8;
      setAmt(e.target.value.slice(0, limit));

      // Format mPmt to display only the first decimal place without rounding
      const formattedMPmt = parseFloat("428.1")
        .toString()
        .replace(/(\.\d).*$/, "$1");
      setMPmt(formattedMPmt);
    }
  };

  const calculatePayment = () => {
    const aprFloat = parseFloat(apr);
    const termInt = parseInt(term, 10);
    const amtFloat = parseFloat(amt);

    if (
      !Number.isNaN(aprFloat) &&
      !Number.isNaN(termInt) &&
      !Number.isNaN(amtFloat)
    ) {
      const monthlyInterest = (aprFloat + 0.12) / 1200;
      const numberOfPayments = termInt * 12;
      const monthlyPayment =
        (amtFloat *
          monthlyInterest *
          (1 + monthlyInterest) ** numberOfPayments) /
        ((1 + monthlyInterest) ** numberOfPayments - 1);
      setMPmt(formatNumberWithComma(monthlyPayment, 1));

      // Calculate total repayment amount without decimals
      const totalRepaymentAmount = Math.round(
        monthlyPayment * numberOfPayments
      );
      setTotalRepayment(
        `${formatNumberWithComma(totalRepaymentAmount, 0).replace(",", ".")}` +
          "€"
      );

      // Calculate final rate
      const finalInterestRate = monthlyInterest * 1200;
      setFinalRate(`${formatNumberWithComma(finalInterestRate, 2)}%`);
    } else {
      setMPmt("");
      setTotalRepayment("");
      setFinalRate("");
    }
  };

  useEffect(() => {
    calculatePayment();
  }, [apr, term, amt]);

  return (
    <>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <Container fluid className="inner-bg loan-calculation-page">
          <Container className="wrapper max-container">
            <form>
              <Row className="justify-content-center">
                <Col sm={10} xl={7}>
                  <div className="mb-3">
                    <label
                      htmlFor="amount"
                      className="color-m mb-3 mona fw-bold"
                    >
                      {form.input_text}
                    </label>
                    <input
                      type="text"
                      className="form-control p-3 amount"
                      id="amount"
                      aria-describedby="amount"
                      maxLength={8}
                      placeholder="100.000€"
                      onChange={handleAmtChange}
                      onWheel={(e) => e.target.blur()}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      }}
                    />
                    <p className="mt-1" style={{ fontSize: "12px" }}>
                      *{customText}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="color-m mona fw-bold">
                      {form.nominal_interest_rate}
                    </p>
                  </div>
                  <div>
                    <h3 className="font-s-35 mb-3 fw-light">{apr}%</h3>
                    <Slider.Root
                      className="SliderRoot"
                      defaultValue={[3]}
                      min={1}
                      max={6}
                      step={0.1}
                      onValueChange={handleAprChange}
                    >
                      <Slider.Track className="SliderTrack">
                        <Slider.Range className="SliderRange" />
                      </Slider.Track>
                      <Slider.Thumb
                        className="SliderThumb"
                        aria-label="Volume"
                      />
                    </Slider.Root>
                  </div>
                  <div className="mt-2">
                    <p className="color-m mona fw-bold">{form.payback}</p>
                  </div>
                  <div>
                    <h3 className="font-s-35 fw-light">{term}</h3>
                    <Slider.Root
                      className="SliderRoot"
                      defaultValue={[30]}
                      min={5}
                      max={40}
                      step={1}
                      onValueChange={handleTermChange}
                    >
                      <Slider.Track className="SliderTrack">
                        <Slider.Range className="SliderRange" />
                      </Slider.Track>
                      <Slider.Thumb
                        className="SliderThumb"
                        aria-label="Volume"
                      />
                    </Slider.Root>
                  </div>
                </Col>
                <Col sm={10} xl={5}>
                  <div className="fixed-height">
                    <p type="hidden" className="invisible mb-3 mona fw-bold">
                      {form.monthly_dose}
                    </p>

                    <Card className="text-center h-100">
                      <Card.Body className="custom-padding">
                        <Card.Title>
                          <p className="monthly">{form.monthly_dose}</p>
                        </Card.Title>
                        <Card.Text
                          className="dose"
                          style={{ "--mPmt": `"${mPmt}€"` }}
                        />
                        <hr />
                        <div className="row align-items-end mt-4">
                          <div className="col-6 text-start">
                            <p className="font-w-700 mona">
                              {form.total_repayment_amount_}
                            </p>
                          </div>
                          <div className="col-6 text-start">
                            <p className="font-w-700 mona">
                              {form.final_interest_rate}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-6 text-start">
                            <p className="small-text">{totalRepayment}</p>
                          </div>
                          <div className="col-6 text-start">
                            <p className="small-text">{finalRate}</p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center justify-content-lg-end">
                <Col className="mt-5 mt-lg-5 note" sm={10} lg={5}>
                  <PrismicRichText field={form.note.richText} />
                </Col>
              </Row>
              <Row className="justify-content-center my-5 d-none">
                <Col className="d-flex justify-content-center">
                  {icons.map((item, index) => (
                    <Anchor
                      href={item.icon_link.url}
                      className="mx-3"
                      target="_blank"
                      key={index}
                    >
                      <GatsbyImage
                        image={item.icon.gatsbyImageData}
                        alt={item.icon.alt || "slider image"}
                        className="slider-img"
                      />
                    </Anchor>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <PrismicRichText field={mydata.app_description.richText} />
                </Col>
              </Row>
            </form>

            <div className="book-now-outline mx-auto mt-5 d-none d-lg-block">
              <Anchor
                href={data.settings.data.book_url.url}
                className="book-now-btn text-center"
              >
                {data.settings.data.book_label}
              </Anchor>
            </div>
          </Container>
        </Container>

        <SliceZone components={components} slices={page.data.body} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query LoanCalculationQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLoanCalculation(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        meta_title
        title
        banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        image_mobile {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        app_description {
          richText
        }
        app_icons {
          icon {
            alt
            gatsbyImageData(placeholder: BLURRED)
            url
          }
          icon_link {
            url
          }
        }
        form_values {
          final_interest_rate
          input_text
          monthly_dose
          nominal_interest_rate
          note {
            richText
          }
          payback
          total_repayment_amount_
        }
        body {
          ... on PrismicLoanCalculationDataBodyLoanCategories {
            id
            slice_label
            slice_type
            items {
              box_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              box_title
              link {
                url
              }
            }
            primary {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                text
              }
              button_label
              button_link {
                url
              }
            }
          }
          ... on PrismicLoanCalculationDataBodyTitleTextButtons {
            id
            items {
              button_label
              link {
                url
              }
            }
            slice_label
            slice_type
            primary {
              background_image {
                alt
                gatsbyImageData(
                  imgixParams: { auto: "compress", q: 100 }
                  placeholder: BLURRED
                )
                url
              }
              description {
                richText
              }
              title1 {
                text
              }
            }
          }
          ... on PrismicLoanCalculationDataBodyFaq {
            id
            items {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title
            }
            slice_label
            slice_type
            primary {
              background_color
              button_label
              button_link {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicLoanCalculationDataBodyOnlineApplication {
            id
            slice_label
            slice_type
            items {
              button_label
              button_link {
                url
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              subtitle
              title {
                text
              }
              list {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              background_color
            }
          }
          ... on PrismicLoanCalculationDataBodyContactInfo {
            id
            items {
              hours
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              phone
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLoanCalculationDataBodyBenefits {
            id
            primary {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title1 {
                text
              }
              boxes
            }
            items {
              boxes_description {
                richText
              }
            }
            slice_label
            slice_type
          }
        }
      }
    }
  }
`;

export default LoanCalculation;
